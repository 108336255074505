import {
    COURT_LIST_GET_LIST,
    COURT_LIST_GET_LIST_SUCCESS,
    COURT_LIST_GET_LIST_ERROR,
    COURT_LIST_ADD_ITEM,
    COURT_LIST_ADD_ITEM_SUCCESS,
    COURT_LIST_ADD_ITEM_ERROR
} from "../actions"

export const getCourtList = () => ({
    type: COURT_LIST_GET_LIST,
})

export const getCourtListSuccess = (items) => ({
    type: COURT_LIST_GET_LIST_SUCCESS,
    payload: items,
})

export const getCourtListError = (error) => ({
    type: COURT_LIST_GET_LIST_ERROR,
    payload: error,
})

export const addCourtItem = (item) => ({
    type: COURT_LIST_ADD_ITEM,
    payload: item,
})

export const addCourtItemSuccess = (items) => ({
    type: COURT_LIST_ADD_ITEM_SUCCESS,
    payload: items,
})

export const addCourtItemError = (error) => ({
    type: COURT_LIST_ADD_ITEM_ERROR,
    payload: error,
})