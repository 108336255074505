import {
  STADIUM_DETAIL_COURT_LIST_GET_LIST,
  STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS,
  STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR,
  STADIUM_DETAIL_COURT_ADD_ITEM,
  STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS,
  STADIUM_DETAIL_COURT_ADD_ITEM_ERROR,
  STADIUM_DETAIL_DELETE_COURT_ITEM,
  STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS,
  STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_ERROR,
  DEFAULT_SCHEDULE,
  DEFAULT_SCHEDULE_SUCCESS,
  DEFAULT_SCHEDULE_ERROR,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_SLOT,
  DELETE_SLOT_SUCCESS,
  DELETE_SLOT_ERROR,
} from "../actions";

/* schedule */

export const getScheduleList = (item) => ({
  type: GET_SCHEDULE,
  payload: item,
});

export const getScheduleListSuccess = (items) => ({
  type: GET_SCHEDULE_SUCCESS,
  payload: items,
});

export const getScheduleListError = (error) => ({
  type: GET_SCHEDULE_ERROR,
  payload: error,
});

export const addSchedule = (item) => ({
  type: ADD_SCHEDULE,
  payload: item,
});

export const addScheduleSuccess = (items) => ({
  type: ADD_SCHEDULE_SUCCESS,
  payload: items,
});

export const addScheduleError = (error) => ({
  type: ADD_SCHEDULE_ERROR,
  payload: error,
});

export const createDefaultSchedule = (item) => ({
  type: DEFAULT_SCHEDULE,
  payload: item,
});

export const createDefaultScheduleSuccess = (items) => ({
  type: DEFAULT_SCHEDULE_SUCCESS,
  payload: items,
});

export const createDefaultScheduleError = (error) => ({
  type: DEFAULT_SCHEDULE_ERROR,
  payload: error,
});

export const deleteSchedule = (item) => ({
  type: DELETE_SCHEDULE,
  payload: item,
});

export const deleteScheduleSuccess = (items) => ({
  type: DELETE_SCHEDULE_SUCCESS,
  payload: items,
});

export const deleteScheduleError = (error) => ({
  type: DELETE_SCHEDULE_ERROR,
  payload: error,
});

export const deleteSlot = (item) => ({
  type: DELETE_SLOT,
  payload: item,
});

export const deleteSlotSuccess = (items) => ({
  type: DELETE_SLOT_SUCCESS,
  payload: items,
});

export const deleteSlotError = (error) => ({
  type: DELETE_SLOT_ERROR,
  payload: error,
});

//Stadium Detail Courts List
export const getStadiumDetailCourtList = (id) => ({
  type: STADIUM_DETAIL_COURT_LIST_GET_LIST,
  payload: id,
});

export const getStadiumDetailCourtListSuccess = (items) => ({
  type: STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getStadiumDetailCourtListError = (error) => ({
  type: STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addStadiumDetailCourtItem = (item) => ({
  type: STADIUM_DETAIL_COURT_ADD_ITEM,
  payload: item,
});

export const addStadiumDetailCourtItemSuccess = (items) => ({
  type: STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addStadiumDetailCourtItemError = (error) => ({
  type: STADIUM_DETAIL_COURT_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteStadiumDetailCourtItem = (item) => ({
  type: STADIUM_DETAIL_DELETE_COURT_ITEM,
  payload: item,
});

export const deleteStadiumDetailCourtItemSuccess = (items) => ({
  type: STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS,
  payload: items,
});

export const deleteStadiumDetailCourtItemError = (error) => ({
  type: STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR,
  payload: error,
});

export const editStadiumDetailCourtItem = (item) => ({
  type: STADIUM_DETAIL_COURT_LIST_EDIT_ITEM,
  payload: item,
});

export const editStadiumDetailCourtItemSuccess = (items) => ({
  type: STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editStadiumDetailCourtItemError = (error) => ({
  type: STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR,
  payload: error,
});
