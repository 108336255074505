import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "../../helpers/axios";
import { servicePath } from "constants/defaultValues";
import { COURT_LIST_GET_LIST, COURT_LIST_ADD_ITEM } from "../actions";

import {
  getCourtListSuccess,
  getCourtListError,
  addCourtItemSuccess,
  addCourtItemError,
} from "./actions";

const apiUrl = `${servicePath}/courts`;

//#region Author List

export function* watchGetCourtList() {
  yield takeEvery(COURT_LIST_GET_LIST, getCourtListItems);
}

function* getCourtListItems() {
  try {
    const response = yield call(getCourtListRequest);
    yield put(getCourtListSuccess(response));
  } catch (error) {
    yield put(getCourtListError(error));
  }
}

const getCourtListRequest = async () => {
  try {
    let response = await axios.get(apiUrl + "/active");
    console.log("COURT response---------------", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add Guide

export function* wathcAddItem() {
  yield takeEvery(COURT_LIST_ADD_ITEM, addCourtItem);
}

function* addCourtItem({ payload }) {
  try {
    const response = yield call(addCourtItemRequest, payload);
    yield put(addCourtItemSuccess(response));
  } catch (error) {
    yield put(addCourtItemError(error));
  }
}

const addCourtItemRequest = async (item) => {
  try {
    if (item.edit) {
      await axios.patch(apiUrl + "/" + item.id, item);
    } else {
      await axios.post(apiUrl, item);
    }

    let response = await axios.get(apiUrl + "/active");
    console.log("COURT response---------------", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

export default function* rootSaga() {
  yield all([fork(watchGetCourtList), fork(wathcAddItem)]);
}
