import {
  STADIUM_LIST_GET_LIST,
  STADIUM_LIST_GET_LIST_SUCCESS,
  STADIUM_LIST_GET_LIST_ERROR,
  STADIUM_LIST_ADD_ITEM,
  STADIUM_LIST_ADD_ITEM_SUCCESS,
  STADIUM_LIST_ADD_ITEM_ERROR,
  STADIUM_LIST_DELETE_ITEM,
  STADIUM_LIST_DELETE_ITEM_ERROR,
  STADIUM_LIST_DELETE_ITEM_SUCCESS,
} from "../actions";

export const getStadiumList = (item) => ({
  type: STADIUM_LIST_GET_LIST,
  payload: item,
});

export const getStadiumListSuccess = (items) => ({
  type: STADIUM_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getStadiumListError = (error) => ({
  type: STADIUM_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addStadiumItem = (item) => ({
  type: STADIUM_LIST_ADD_ITEM,
  payload: item,
});

export const addStadiumItemSuccess = (items) => ({
  type: STADIUM_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addStadiumItemError = (error) => ({
  type: STADIUM_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteStadiumItem = (item) => ({
  type: STADIUM_LIST_DELETE_ITEM,
  payload: item,
});

export const deleteStadiumItemSuccess = (items) => ({
  type: STADIUM_LIST_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteStadiumItemError = (error) => ({
  type: STADIUM_LIST_DELETE_ITEM_ERROR,
  payload: error,
});
