import {
  STADIUM_STAFF_LIST_GET_LIST,
  STADIUM_STAFF_LIST_GET_LIST_SUCCESS,
  STADIUM_STAFF_LIST_GET_LIST_ERROR,
  STADIUM_STAFF_LIST_ADD_ITEM,
  STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_ADD_ITEM_ERROR,
  STADIUM_STAFF_LIST_EDIT_ITEM,
  STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_EDIT_ITEM_ERROR,
  STADIUM_STAFF_LIST_DELETE_ITEM,
  STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_DELETE_ITEM_ERROR,
} from '../actions';

export const getStadiumStaffList = () => ({
  type: STADIUM_STAFF_LIST_GET_LIST,
});

export const getStadiumStaffListSuccess = (items) => ({
  type: STADIUM_STAFF_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getStadiumStaffListError = (error) => ({
  type: STADIUM_STAFF_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addStadiumStaffItem = (item) => ({
  type: STADIUM_STAFF_LIST_ADD_ITEM,
  payload: item,
});

export const addStadiumStaffItemSuccess = (items) => ({
  type: STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addStadiumStaffItemError = (error) => ({
  type: STADIUM_STAFF_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteStadiumStaffItem = (item) => ({
  type: STADIUM_STAFF_LIST_DELETE_ITEM,
  payload: item,
});

export const deleteStadiumStaffItemSuccess = (items) => ({
  type: STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteStadiumStaffItemError = (error) => ({
  type: STADIUM_STAFF_LIST_DELETE_ITEM_ERROR,
  payload: error,
});

export const editStadiumStaffItem = (item) => ({
  type: STADIUM_STAFF_LIST_EDIT_ITEM,
  payload: item,
});

export const editStadiumStaffItemSuccess = (items) => ({
  type: STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editStadiumStaffItemError = (error) => ({
  type: STADIUM_STAFF_LIST_EDIT_ITEM_ERROR,
  payload: error,
});
