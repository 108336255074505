import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import todoSagas from "./todo/saga";

import stadiumListSagas from "./stadium/saga";
import sportListSagas from "./sport/saga";
import courtListSagas from "./court/saga";
import stadiumDetailSagas from "./stadium-detail/saga";
import bookingsSagas from "./bookings/saga";
import stadiumAdminsSagas from "./stadiumAdmins/saga";
import stadiumStaffSagas from "./stadium-staff/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    stadiumAdminsSagas(),
    stadiumStaffSagas(),
    stadiumListSagas(),
    sportListSagas(),
    courtListSagas(),
    stadiumDetailSagas(),
    bookingsSagas(),
  ]);
}
