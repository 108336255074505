import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "../../helpers/axios";
import { servicePath } from "constants/defaultValues";
import {
  STADIUM_LIST_GET_LIST,
  STADIUM_LIST_ADD_ITEM,
  STADIUM_LIST_DELETE_ITEM,
} from "../actions";

import {
  getStadiumListSuccess,
  getStadiumListError,
  addStadiumItemSuccess,
  addStadiumItemError,
  deleteStadiumItemSuccess,
  deleteStadiumItemError,
} from "./actions";

const apiUrl = `${servicePath}/stadiums`;

//#region Author List

export function* watchGetStadiumList() {
  yield takeEvery(STADIUM_LIST_GET_LIST, getStadiumListItems);
}

function* getStadiumListItems() {
  try {
    const response = yield call(getStadiumListRequest);
    yield put(getStadiumListSuccess(response));
  } catch (error) {
    yield put(getStadiumListError(error));
  }
}

const getStadiumListRequest = async () => {
  try {
    let user = JSON.parse(localStorage.getItem("app_current_user"));

    let response;
    if (user?.roles === "Stadium-Admin" || user?.roles === "Stadium-Staff") {
      response = await axios.get(`${apiUrl}?userId=${user?.id}`);
    } else {
      response = await axios.get(`${apiUrl}`);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add Guide

export function* watchAddItem() {
  yield takeEvery(STADIUM_LIST_ADD_ITEM, addStadiumItem);
}

function* addStadiumItem({ payload }) {
  try {
    const response = yield call(addStadiumItemRequest, payload);
    yield put(addStadiumItemSuccess(response));
  } catch (error) {
    yield put(addStadiumItemError(error));
  }
}

const addStadiumItemRequest = async (item) => {
  try {
    console.log("herere");
    if (item.edit) {
      await axios.patch(apiUrl + "/" + item.id, item);
    } else {
      console.log("in the post call of add stadium", item);
      await axios.post(apiUrl, item);
    }

    let response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#region Delete Stadium

export function* wathcDeleteItem() {
  yield takeEvery(STADIUM_LIST_DELETE_ITEM, deleteStadiumItem);
}

function* deleteStadiumItem({ payload }) {
  try {
    yield call(deleteStadiumItemRequest, payload);
    yield put(deleteStadiumItemSuccess(payload));
  } catch (error) {
    yield put(deleteStadiumItemError(error));
  }
}

const deleteStadiumItemRequest = async (item) => {
  try {
    let res = await axios.delete(apiUrl + "/" + item);
    //let response = await axios.get(apiUrl);

    console.log("del in res", res.data);
    return response.data;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};

//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetStadiumList),
    fork(watchAddItem),
    fork(wathcDeleteItem),
  ]);
}
