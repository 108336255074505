import {
  BOOKING_LIST_GET_LIST,
  BOOKING_LIST_GET_LIST_SUCCESS,
  BOOKING_LIST_GET_LIST_ERROR,
  BOOKING_COURT_LIST_GET_LIST,
  BOOKING_COURT_LIST_GET_LIST_SUCCESS,
  BOOKING_COURT_LIST_GET_LIST_ERROR,
  BOOKING_STADIUM_LIST_GET_LIST,
  BOOKING_STADIUM_LIST_GET_LIST_SUCCESS,
  BOOKING_STADIUM_LIST_GET_LIST_ERROR,
  BOOKING_LIST_ADD_ITEM,
  BOOKING_LIST_ADD_ITEM_SUCCESS,
  BOOKING_LIST_ADD_ITEM_ERROR,
  BOOKING_LIST_EDIT_ITEM,
  BOOKING_LIST_EDIT_ITEM_SUCCESS,
  BOOKING_LIST_EDIT_ITEM_ERROR,
  BOOKING_LIST_EDIT_ITEM_STATUS,
  BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS,
  BOOKING_LIST_EDIT_ITEM_STATUS_ERROR,
  BOOKING_LIST_DELETE_ITEM,
  BOOKING_LIST_DELETE_ITEM_SUCCESS,
  BOOKING_LIST_DELETE_ITEM_ERROR,
} from "../actions";

export const getBookingList = () => ({
  type: BOOKING_LIST_GET_LIST,
});

export const getBookingListSuccess = (items) => ({
  type: BOOKING_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getBookingListError = (error) => ({
  type: BOOKING_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getCourtBookingList = (id) => ({
  type: BOOKING_COURT_LIST_GET_LIST,
  payload: id,
});

export const getCourtBookingListSuccess = (items) => ({
  type: BOOKING_COURT_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getCourtBookingListError = (error) => ({
  type: BOOKING_COURT_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getStadiumBookingList = (id) => ({
  type: BOOKING_STADIUM_LIST_GET_LIST,
  payload: id,
});

export const getStadiumBookingListSuccess = (items) => ({
  type: BOOKING_STADIUM_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getStadiumBookingListError = (error) => ({
  type: BOOKING_STADIUM_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addBookingItem = (item) => ({
  type: BOOKING_LIST_ADD_ITEM,
  payload: item,
});

export const addBookingItemSuccess = (items) => ({
  type: BOOKING_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addBookingItemError = (error) => ({
  type: BOOKING_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteBookingItem = (item) => ({
  type: BOOKING_LIST_DELETE_ITEM,
  payload: item,
});

export const deleteBookingItemSuccess = (items) => ({
  type: BOOKING_LIST_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteBookingItemError = (error) => ({
  type: BOOKING_LIST_DELETE_ITEM_ERROR,
  payload: error,
});

export const editBookingItem = (item) => ({
  type: BOOKING_LIST_EDIT_ITEM,
  payload: item,
});

export const editBookingItemSuccess = (items) => ({
  type: BOOKING_LIST_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editBookingItemError = (error) => ({
  type: BOOKING_LIST_EDIT_ITEM_ERROR,
  payload: error,
});

export const editBookingItemStatus = (item) => ({
  type: BOOKING_LIST_EDIT_ITEM_STATUS,
  payload: item,
});

export const editBookingItemStatusSuccess = (items) => ({
  type: BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS,
  payload: items,
});

export const editBookingItemStatusError = (error) => ({
  type: BOOKING_LIST_EDIT_ITEM_STATUS_ERROR,
  payload: error,
});
