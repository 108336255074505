import {
  STADIUM_ADMIN_LIST_GET_LIST,
  STADIUM_ADMIN_LIST_GET_LIST_SUCCESS,
  STADIUM_ADMIN_LIST_GET_LIST_ERROR,
  STADIUM_ADMIN_LIST_ADD_ITEM,
  STADIUM_ADMIN_LIST_ADD_ITEM_SUCCESS,
  STADIUM_ADMIN_LIST_ADD_ITEM_ERROR,
  STADIUM_ADMIN_LIST_EDIT_ITEM,
  STADIUM_ADMIN_LIST_EDIT_ITEM_SUCCESS,
  STADIUM_ADMIN_LIST_EDIT_ITEM_ERROR,
  STADIUM_ADMIN_LIST_DELETE_ITEM,
  STADIUM_ADMIN_LIST_DELETE_ITEM_SUCCESS,
  STADIUM_ADMIN_LIST_DELETE_ITEM_ERROR,
} from "../actions";

export const getStadiumAdminList = (item) => ({
  type: STADIUM_ADMIN_LIST_GET_LIST,
  payload: item,
});

export const getStadiumAdminListSuccess = (items) => ({
  type: STADIUM_ADMIN_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getStadiumAdminListError = (error) => ({
  type: STADIUM_ADMIN_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addStadiumAdminItem = (item) => ({
  type: STADIUM_ADMIN_LIST_ADD_ITEM,
  payload: item,
});

export const addStadiumAdminItemSuccess = (items) => ({
  type: STADIUM_ADMIN_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addStadiumAdminItemError = (error) => ({
  type: STADIUM_ADMIN_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteStadiumAdminItem = (item) => ({
  type: STADIUM_ADMIN_LIST_DELETE_ITEM,
  payload: item,
});

export const deleteStadiumAdminItemSuccess = (items) => ({
  type: STADIUM_ADMIN_LIST_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteStadiumAdminItemError = (error) => ({
  type: STADIUM_ADMIN_LIST_DELETE_ITEM_ERROR,
  payload: error,
});

export const editStadiumAdminItem = (item) => ({
  type: STADIUM_ADMIN_LIST_EDIT_ITEM,
  payload: item,
});

export const editStadiumAdminItemSuccess = (items) => ({
  type: STADIUM_ADMIN_LIST_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editStadiumAdminItemError = (error) => ({
  type: STADIUM_ADMIN_LIST_EDIT_ITEM_ERROR,
  payload: error,
});
