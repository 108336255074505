import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { servicePath } from 'constants/defaultValues';
import {
  STADIUM_STAFF_LIST_GET_LIST,
  STADIUM_STAFF_LIST_ADD_ITEM,
  STADIUM_STAFF_LIST_EDIT_ITEM,
  STADIUM_STAFF_LIST_DELETE_ITEM,
} from '../actions';

import {
  getStadiumStaffListSuccess,
  getStadiumStaffListError,
  addStadiumStaffItemSuccess,
  addStadiumStaffItemError,
  editStadiumStaffItemSuccess,
  editStadiumStaffItemError,
  deleteStadiumStaffItemSuccess,
  deleteStadiumStaffItemError,
} from './actions';

const apiUrl = `${servicePath}/courts`;

//#region StadiumStaff List

export function* watchGetList() {
  yield takeEvery(STADIUM_STAFF_LIST_GET_LIST, getStadiumStaffListItems);
}

function* getStadiumStaffListItems() {
  try {
    const response = yield call(getStadiumStaffListRequest);
    yield put(getStadiumStaffListSuccess(response));
  } catch (error) {
    yield put(getStadiumStaffListError(error));
  }
}

const getStadiumStaffListRequest = async () => {
  try {
    let response = await axios.get(apiUrl + '/active');
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add StadiumStaff

export function* wathcAddItem() {
  yield takeEvery(STADIUM_STAFF_LIST_ADD_ITEM, addStadiumStaffItem);
}

function* addStadiumStaffItem({ payload }) {
  try {
    const response = yield call(addStadiumStaffItemRequest, payload);
    yield put(addStadiumStaffItemSuccess(response));
  } catch (error) {
    yield put(addStadiumStaffItemError(error));
  }
}

const addStadiumStaffItemRequest = async (item) => {
  try {
    await axios.post(apiUrl, item);
    let response = await axios.get(apiUrl + '/active');
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Edit StadiumStaff

export function* wathcEditItem() {
  yield takeEvery(STADIUM_STAFF_LIST_EDIT_ITEM, editStadiumStaffItem);
}

function* editStadiumStaffItem({ payload }) {
  try {
    const response = yield call(editStadiumStaffItemRequest, payload);
    yield put(editStadiumStaffItemSuccess(response));
  } catch (error) {
    yield put(editStadiumStaffItemError(error));
  }
}

const editStadiumStaffItemRequest = async (item) => {
  try {
    await axios.patch(apiUrl + '/' + item.id, item);
    let response = await axios.get(apiUrl + '/active');
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Delete StadiumStaff

export function* wathcDeleteItem() {
  yield takeEvery(STADIUM_STAFF_LIST_DELETE_ITEM, deleteStadiumStaffItem);
}

function* deleteStadiumStaffItem({ payload }) {
  try {
    yield call(deleteStadiumStaffItemRequest, payload);
    yield put(deleteStadiumStaffItemSuccess(payload));
  } catch (error) {
    yield put(deleteStadiumStaffItemError(error));
  }
}

const deleteStadiumStaffItemRequest = async (item) => {
  try {
    let res = await axios.delete(apiUrl + '/' + item);
    console.log('del in res', res.data);
    return res.data;
  } catch (error) {
    console.log('err', error);
    return error;
  }
};

//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcDeleteItem),
  ]);
}
