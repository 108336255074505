import { actions } from "react-table";
import {
  STADIUM_DETAIL_COURT_LIST_GET_LIST,
  STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS,
  STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR,
  STADIUM_DETAIL_COURT_ADD_ITEM,
  STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS,
  STADIUM_DETAIL_COURT_ADD_ITEM_ERROR,
  STADIUM_DETAIL_DELETE_COURT_ITEM,
  STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS,
  STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_ERROR,
  DEFAULT_SCHEDULE,
  DEFAULT_SCHEDULE_SUCCESS,
  DEFAULT_SCHEDULE_ERROR,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_SLOT,
  DELETE_SLOT_SUCCESS,
  DELETE_SLOT_ERROR,
} from "../actions";

const INIT_STATE = {
  courtItems: [],
  scheduleList: [],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    /* schedule*/
    case GET_SCHEDULE:
      return { ...state, loading: false };
    case GET_SCHEDULE_SUCCESS:
      return { ...state, loading: true, scheduleList: action.payload };
    case GET_SCHEDULE_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ADD_SCHEDULE:
      return { ...state, loading: false };
    case ADD_SCHEDULE_SUCCESS:
      return { ...state, loading: true, scheduleList: action.payload };
    case ADD_SCHEDULE_ERROR:
      return { ...state, loading: true, error: action.payload };
    case DEFAULT_SCHEDULE:
      return { ...state, loading: false };
    case DEFAULT_SCHEDULE_SUCCESS:
      return { ...state, loading: true, };
    case DEFAULT_SCHEDULE_ERROR:
      return { ...state, loading: true, error: action.payload };
    case DELETE_SCHEDULE:
      return { ...state, loading: false };
    case DELETE_SCHEDULE_SUCCESS:
      const scheduleListt = state.scheduleList.filter(
        (element) => element.id != action.payload.id
      );
      console.log(action.payload, scheduleListt);
      return { ...state, loading: true, scheduleList: [...scheduleListt] };
    case DELETE_SCHEDULE_ERROR:
      return { ...state, loading: true, scheduleList: [...scheduleList] };

    case DELETE_SLOT:
      return { ...state, loading: false };
    case DELETE_SLOT_SUCCESS:
      console.log(action.payload);
      return { ...state, loading: true, scheduleList: action.payload };
    case DELETE_SLOT_ERROR:
      return { ...state, loading: true, scheduleList: [] };
    //Stadium Detail Courts
    case STADIUM_DETAIL_COURT_LIST_GET_LIST:
      return { ...state, loading: false };
    case STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS: {
      console.log(action.payload, "action.payload");
      return { ...state, loading: true, courtItems: action.payload };
    }
    case STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_DETAIL_COURT_ADD_ITEM:
      return { ...state, loading: false };
    case STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, courtItems: action.payload };
    case STADIUM_DETAIL_COURT_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_DETAIL_DELETE_COURT_ITEM:
      return { ...state, loading: false };
    case STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS: {
      const courtItems = state.courtItems.filter(
        (element) => element.id !== action.payload
      );
      return { ...state, loading: true, courtItems: [...courtItems] };
    }
    case STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_DETAIL_COURT_LIST_EDIT_ITEM:
      return { ...state, loading: false };
    case STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS:
      return { ...state, loading: true, courtItems: action.payload };
    case STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
