import {
  BOOKING_LIST_GET_LIST,
  BOOKING_LIST_GET_LIST_SUCCESS,
  BOOKING_LIST_GET_LIST_ERROR,
  BOOKING_COURT_LIST_GET_LIST,
  BOOKING_COURT_LIST_GET_LIST_SUCCESS,
  BOOKING_COURT_LIST_GET_LIST_ERROR,
  BOOKING_STADIUM_LIST_GET_LIST,
  BOOKING_STADIUM_LIST_GET_LIST_SUCCESS,
  BOOKING_STADIUM_LIST_GET_LIST_ERROR,
  BOOKING_LIST_ADD_ITEM,
  BOOKING_LIST_ADD_ITEM_SUCCESS,
  BOOKING_LIST_ADD_ITEM_ERROR,
  BOOKING_LIST_EDIT_ITEM,
  BOOKING_LIST_EDIT_ITEM_SUCCESS,
  BOOKING_LIST_EDIT_ITEM_ERROR,
  BOOKING_LIST_EDIT_ITEM_STATUS,
  BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS,
  BOOKING_LIST_EDIT_ITEM_STATUS_ERROR,
  BOOKING_LIST_DELETE_ITEM,
  BOOKING_LIST_DELETE_ITEM_SUCCESS,
  BOOKING_LIST_DELETE_ITEM_ERROR,
} from "../actions";

const INIT_STATE = {
  bookingItems: [],
  error: "",
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BOOKING_LIST_GET_LIST:
      return { ...state, loading: false };
    case BOOKING_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_COURT_LIST_GET_LIST:
      return { ...state, loading: false };
    case BOOKING_COURT_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_COURT_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_STADIUM_LIST_GET_LIST:
      return { ...state, loading: false };
    case BOOKING_STADIUM_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_STADIUM_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_LIST_ADD_ITEM:
      return { ...state, loading: false };
    case BOOKING_LIST_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_LIST_EDIT_ITEM:
      return { ...state, loading: false };
    case BOOKING_LIST_EDIT_ITEM_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_LIST_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_LIST_EDIT_ITEM_STATUS:
      return { ...state, loading: false };
    case BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS:
      return { ...state, loading: true, bookingItems: action.payload };
    case BOOKING_LIST_EDIT_ITEM_STATUS_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BOOKING_LIST_DELETE_ITEM:
      return { ...state, loading: false };
    case BOOKING_LIST_DELETE_ITEM_SUCCESS: {
      const bookingItems = state.bookingItems.filter(
        (element) => element.id !== action.payload
      );
      return { ...state, loading: true, bookingItems: [...bookingItems] };
    }
    case BOOKING_LIST_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
