import {
  SPORT_LIST_GET_LIST,
  SPORT_LIST_GET_LIST_SUCCESS,
  SPORT_LIST_GET_LIST_ERROR,
  SPORT_LIST_ADD_ITEM,
  SPORT_LIST_ADD_ITEM_SUCCESS,
  SPORT_LIST_ADD_ITEM_ERROR,
  SPORT_LIST_DELETE_ITEM,
  SPORT_LIST_DELETE_ITEM_ERROR,
  SPORT_LIST_DELETE_ITEM_SUCCESS,
} from "../actions";

export const getSportList = (item) => ({
  type: SPORT_LIST_GET_LIST,
  payload: item,
});

export const getSportListSuccess = (items) => ({
  type: SPORT_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getSportListError = (error) => ({
  type: SPORT_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addSportItem = (item) => ({
  type: SPORT_LIST_ADD_ITEM,
  payload: item,
});

export const addSportItemSuccess = (items) => ({
  type: SPORT_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addSportItemError = (error) => ({
  type: SPORT_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const deleteSportItem = (item) => ({
  type: SPORT_LIST_DELETE_ITEM,
  payload: item,
});

export const deleteSportItemSuccess = (items) => ({
  type: SPORT_LIST_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteSportItemError = (error) => ({
  type: SPORT_LIST_DELETE_ITEM_ERROR,
  payload: error,
});
