import {
    COURT_LIST_GET_LIST,
    COURT_LIST_GET_LIST_SUCCESS,
    COURT_LIST_GET_LIST_ERROR,
    COURT_LIST_ADD_ITEM,
    COURT_LIST_ADD_ITEM_SUCCESS,
    COURT_LIST_ADD_ITEM_ERROR
} from "../actions"

const INIT_STATE = {
    courtItems: [],
    error: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case COURT_LIST_GET_LIST:
            return { ...state, loading: false }
        case COURT_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, courtItems: action.payload }
        case COURT_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }
        case COURT_LIST_ADD_ITEM:
            return { ...state, loading: false }
        case COURT_LIST_ADD_ITEM_SUCCESS:
            return { ...state, loading: true, courtItems: action.payload }
        case COURT_LIST_ADD_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        default:
            return { ...state }
    }
}
