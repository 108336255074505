import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "../../helpers/axios";
import { servicePath } from "constants/defaultValues";
import {
  STADIUM_DETAIL_COURT_LIST_GET_LIST,
  STADIUM_DETAIL_COURT_ADD_ITEM,
  STADIUM_DETAIL_DELETE_COURT_ITEM,
  STADIUM_DETAIL_COURT_LIST_EDIT_ITEM,
  GET_SCHEDULE,
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  DELETE_SLOT,
  DEFAULT_SCHEDULE,
} from "../actions";

import {
  getStadiumDetailCourtListSuccess,
  getStadiumDetailCourtListError,
  addStadiumDetailCourtItemSuccess,
  addStadiumDetailCourtItemError,
  deleteStadiumDetailCourtItemSuccess,
  deleteStadiumDetailCourtItemError,
  editStadiumDetailCourtItemSuccess,
  editStadiumDetailCourtItemError,
  getScheduleListSuccess,
  getScheduleListError,
  addScheduleSuccess,
  addScheduleError,
  deleteScheduleSuccess,
  deleteScheduleError,
  deleteSlotSuccess,
  deleteSlotError,
  createDefaultScheduleSuccess,
  createDefaultScheduleError,
} from "./actions";

const apiUrl = `${servicePath}/stadiums`;
const scheduleUrl = `${servicePath}/schedules`;
const updatedApiUrl = `${servicePath}/courts`;
const slotApiUrl = `${servicePath}/slots`;

//# Schedule Start

//#region Schedule List

export function* watchGetScheduleList() {
  yield takeEvery(GET_SCHEDULE, getScheduleList);
}

function* getScheduleList({ payload }) {
  try {
    console.log(payload);
    const response = yield call(getScheduleListByCourtIdRequest, payload);
    yield put(getScheduleListSuccess(response));
  } catch (error) {
    yield put(getScheduleListError(error));
  }
}

const getScheduleListByCourtIdRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");

    const headers = {
      "x-access-token": token,
    };
    let response = await axios.get(updatedApiUrl + "/" + item + "/schedules", {
      headers: headers,
    });
    console.log("response", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add Schedule
export function* watchCreateDefaultSchedule() {
  yield takeEvery(DEFAULT_SCHEDULE, createDefaultSchedule);
}

function* createDefaultSchedule({ payload }) {
  try {
    const response = yield call(createDefaultScheduleRequest, payload);
    yield put(createDefaultScheduleSuccess(response));
  } catch (error) {
    yield put(createDefaultScheduleError(error));
  }
}

const createDefaultScheduleRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };

    console.log("createDefaultScheduleRequest", item);
    const respone = await axios.post(scheduleUrl + "/default", item, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
//#region ADD Schedule

//#region Add Schedule
export function* watchAddSchedule() {
  yield takeEvery(ADD_SCHEDULE, addSchedule);
}

function* addSchedule({ payload }) {
  try {
    const response = yield call(addScheduleRequest, payload);
    yield put(addScheduleSuccess(response));
  } catch (error) {
    yield put(addScheduleError(error));
  }
}

const addScheduleRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };
    if (item.id) {
      await axios.patch(scheduleUrl + "/" + item.id, item, {
        headers: headers,
      });
    } else {
      console.log("in the post call of add stadium", item);
      await axios.post(scheduleUrl, item);
    }

    let response = await axios.get(
      updatedApiUrl + "/" + item.courtId + "/schedules",
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
//#region ADD Schedule

//#region Delete Schedule

export function* wathcDeleteSchedule() {
  yield takeEvery(DELETE_SCHEDULE, deleteSchedule);
}

function* deleteSchedule({ payload }) {
  try {
    const response = yield call(deleteScheduleRequest, payload);
    yield put(deleteScheduleSuccess(response));
  } catch (error) {
    yield put(deleteScheduleError(error));
  }
}

const deleteScheduleRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };
    let res = await axios.delete(scheduleUrl + "/" + item, {
      headers: headers,
    });
    //let response = await axios.get(apiUrl);

    console.log("del in res", res.data);
    return res.data;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};

//#endregion

//#region Delete Slot

export function* wathcDeleteSlot() {
  yield takeEvery(DELETE_SLOT, deleteSlot);
}

function* deleteSlot({ payload }) {
  try {
    const response = yield call(deleteSlotRequest, payload);
    yield put(deleteSlotSuccess(response));
  } catch (error) {
    yield put(deleteSlotError(error));
  }
}

const deleteSlotRequest = async (item) => {
  try {
    console.log({ item });
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };

    await axios.delete(slotApiUrl + "/" + item.slotId, {
      headers: headers,
    });

    let response = await axios.get(
      updatedApiUrl + "/" + +item.courtId + "/schedules",
      {
        headers: headers,
      }
    );
    console.log("Stadium response---------------", response);
    return response.data;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};

//#endregion

// end schedule

//#endregion

//#region Stadium Detail Court List

export function* watchGetStadiumDetailCourtList() {
  yield takeEvery(
    STADIUM_DETAIL_COURT_LIST_GET_LIST,
    getStadiumDetailCourtListItems
  );
}

function* getStadiumDetailCourtListItems({ payload }) {
  console.log(payload, "payload in saga");
  try {
    const response = yield call(getStadiumDetailCourtListRequest, payload);
    yield put(getStadiumDetailCourtListSuccess(response));
  } catch (error) {
    yield put(getStadiumDetailCourtListError(error));
  }
}

const getStadiumDetailCourtListRequest = async (id) => {
  console.log(id, "id in saga");
  try {
    const token = localStorage.getItem("accessToken");

    const headers = {
      "x-access-token": token,
    };
    let response = await axios.get(updatedApiUrl + "/stadium/" + id, {
      headers: headers,
    });
    console.log("Courts response---------------", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Stadium Detail Add Court

export function* watchStadiumDetailAddCourtItem() {
  yield takeEvery(STADIUM_DETAIL_COURT_ADD_ITEM, addStadiumDetailCourtItem);
}

function* addStadiumDetailCourtItem({ payload }) {
  try {
    const response = yield call(addStadiumDetailCourtAddItemRequest, payload);
    yield put(addStadiumDetailCourtItemSuccess(response));
  } catch (error) {
    yield put(addStadiumDetailCourtItemError(error));
  }
}

const addStadiumDetailCourtAddItemRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");
    console.log("token in add court by clicnic", token);
    const headers = {
      "x-access-token": token,
    };
    if (item.edit) {
      await axios.put(updatedApiUrl + "/" + item.id, item);
    } else {
      console.log("in the post call of add stadium", item);
      await axios.post(updatedApiUrl, item, {
        headers: headers,
      });
    }

    let response = await axios.get(updatedApiUrl + "/stadium/" + item.stadiumId, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

//#region Stadium Detail Delete Court

export function* wathcStadiumDetailDeleteCourtItem() {
  yield takeEvery(
    STADIUM_DETAIL_DELETE_COURT_ITEM,
    deleteStadiumDetailCourtItem
  );
}

function* deleteStadiumDetailCourtItem({ payload }) {
  try {
    yield call(deleteStadiumDetailCourtItemRequest, payload);
    yield put(deleteStadiumDetailCourtItemSuccess(payload));
  } catch (error) {
    yield put(deleteStadiumDetailCourtItemError(error));
  }
}

const deleteStadiumDetailCourtItemRequest = async (item) => {
  console.log("delete  ", updatedApiUrl, item);
  try {
    const token = localStorage.getItem("accessToken");

    console.log("item.edit", item);
    const headers = {
      "x-access-token": token,
    };
    let res = await axios.delete(updatedApiUrl + "/" + item, {
      headers: headers,
    });
    //let response = await axios.get(apiUrl);

    console.log("del in res", res.data);
    return res.data.id;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};

//#endregion

// //#region Stadium Detail Edit Court

// export function* wathcStadiumDetailEditCourtItem() {
//   yield takeEvery(STADIUM_DETAIL_DELETE_COURT_ITEM, editStadiumDetailCourtItem);
// }

// function* editStadiumDetailCourtItem({ payload }) {
//   try {
//     yield call(editStadiumDetailCourtItemRequest, payload);
//     yield put(editStadiumDetailCourtItemSuccess(payload));
//   } catch (error) {
//     yield put(editStadiumDetailCourtItemError(error));
//   }
// }

// const editStadiumDetailCourtItemRequest = async (item) => {
//   try {
//     let res = await axios.put(updatedApiUrl + "/" + item);
//     //let response = await axios.get(apiUrl);

//     console.log("del in res", res.data);
//     return res.data;
//   } catch (error) {
//     console.log("err", error);
//     return error;
//   }
// };

// //#endregion

//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetScheduleList),
    fork(watchAddSchedule),
    fork(watchCreateDefaultSchedule),
    fork(wathcDeleteSchedule),
    fork(wathcDeleteSlot),
    // fork(watchGetList),
    fork(watchGetStadiumDetailCourtList),
    fork(watchStadiumDetailAddCourtItem),
    fork(wathcStadiumDetailDeleteCourtItem),
  ]);
}
