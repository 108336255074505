import {
  STADIUM_STAFF_LIST_GET_LIST,
  STADIUM_STAFF_LIST_GET_LIST_SUCCESS,
  STADIUM_STAFF_LIST_GET_LIST_ERROR,
  STADIUM_STAFF_LIST_ADD_ITEM,
  STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_ADD_ITEM_ERROR,
  STADIUM_STAFF_LIST_EDIT_ITEM,
  STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_EDIT_ITEM_ERROR,
  STADIUM_STAFF_LIST_DELETE_ITEM,
  STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS,
  STADIUM_STAFF_LIST_DELETE_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  stadiumStaffItems: [],
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STADIUM_STAFF_LIST_GET_LIST:
      return { ...state, loading: false };
    case STADIUM_STAFF_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, stadiumStaffItems: action.payload };
    case STADIUM_STAFF_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_STAFF_LIST_ADD_ITEM:
      return { ...state, loading: false };
    case STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, stadiumStaffItems: action.payload };
    case STADIUM_STAFF_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_STAFF_LIST_EDIT_ITEM:
      return { ...state, loading: false };
    case STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS:
      return { ...state, loading: true, stadiumStaffItems: action.payload };
    case STADIUM_STAFF_LIST_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_STAFF_LIST_DELETE_ITEM:
      return { ...state, loading: false };
    case STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS: {
      const stadiumStaffItems = state.stadiumStaffItems.filter(
        (element) => element.id !== action.payload
      );
      return {
        ...state,
        loading: true,
        stadiumStaffItems: [...stadiumStaffItems],
      };
    }
    case STADIUM_STAFF_LIST_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
