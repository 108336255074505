/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* SCHEDULE DETAIL */
export const GET_SCHEDULE = "GET_SCHEDULE";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_ERROR = "ADD_SCHEDULE_ERROR";
export const DEFAULT_SCHEDULE = "DEFAULT_SCHEDULE";
export const DEFAULT_SCHEDULE_SUCCESS = "DEFAULT_SCHEDULE_SUCCESS";
export const DEFAULT_SCHEDULE_ERROR = "DEFAULT_SCHEDULE_ERROR";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";
export const DELETE_SLOT = "DELETE_SLOT";
export const DELETE_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS";
export const DELETE_SLOT_ERROR = "DELETE_SLOT_ERROR";

export const STADIUM_DETAIL_COURT_LIST_GET_LIST =
  "STADIUM_DETAIL_COURT_LIST_GET_LIST";
export const STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS =
  "STADIUM_DETAIL_COURT_LIST_GET_LIST_SUCCESS";
export const STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR =
  "STADIUM_DETAIL_COURT_LIST_GET_LIST_ERROR";
export const STADIUM_DETAIL_COURT_ADD_ITEM = "STADIUM_DETAIL_COURT_ADD_ITEM";
export const STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS =
  "STADIUM_DETAIL_COURT_ADD_ITEM_SUCCESS";
export const STADIUM_DETAIL_COURT_ADD_ITEM_ERROR =
  "STADIUM_DETAIL_COURT_ADD_ITEM_ERROR";
export const STADIUM_DETAIL_COURT_LIST_EDIT_ITEM =
  "STADIUM_DETAIL_COURT_LIST_EDIT_ITEM";
export const STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS =
  "STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_SUCCESS";
export const STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR =
  "STADIUM_DETAIL_COURT_LIST_EDIT_ITEM_ERROR";
export const STADIUM_DETAIL_DELETE_COURT_ITEM =
  "STADIUM_DETAIL_DELETE_COURT_ITEM";
export const STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS =
  "STADIUM_DETAIL_DELETE_COURT_ITEM_SUCCESS";
export const STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR =
  "STADIUM_DETAIL_DELETE_COURT_ITEM_ERROR";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* Categories LIST APP */
export const CATEGORY_LIST_GET_LIST = "CATEGORY_LIST_GET_LIST";
export const CATEGORY_LIST_GET_LIST_SUCCESS = "CATEGORY_LIST_GET_LIST_SUCCESS";
export const CATEGORY_LIST_GET_LIST_ERROR = "CATEGORY_LIST_GET_LIST_ERROR";
export const CATEGORY_LIST_ADD_ITEM = "CATEGORY_LIST_ADD_ITEM";
export const CATEGORY_LIST_ADD_ITEM_SUCCESS = "CATEGORY_LIST_ADD_ITEM_SUCCESS";
export const CATEGORY_LIST_ADD_ITEM_ERROR = "CATEGORY_LIST_ADD_ITEM_ERROR";
export const CATEGORY_LIST_DELETE_ITEM = "CATEGORY_LIST_DELETE_ITEM";
export const CATEGORY_LIST_DELETE_ITEM_SUCCESS =
  "CATEGORY_LIST_DELETE_ITEM_SUCCESS";
export const CATEGORY_LIST_DELETE_ITEM_ERROR =
  "CATEGORY_LIST_DELETE_ITEM_ERROR";
export const CATEGORY_LIST_EDIT_ITEM = "CATEGORY_LIST_EDIT_ITEM";
export const CATEGORY_LIST_EDIT_ITEM_SUCCESS =
  "CATEGORY_LIST_EDIT_ITEM_SUCCESS";
export const CATEGORY_LIST_EDIT_ITEM_ERROR = "CATEGORY_LIST_EDIT_ITEM_ERROR";

/* AUTHOR LIST APP */
export const AUTHOR_LIST_GET_LIST = "AUTHOR_LIST_GET_LIST";
export const AUTHOR_LIST_GET_LIST_SUCCESS = "AUTHOR_LIST_GET_LIST_SUCCESS";
export const AUTHOR_LIST_GET_LIST_ERROR = "AUTHOR_LIST_GET_LIST_ERROR";
export const AUTHOR_LIST_ADD_ITEM = "AUTHOR_LIST_ADD_ITEM";
export const AUTHOR_LIST_EDIT_ITEM = "AUTHOR_LIST_EDIT_ITEM";
export const AUTHOR_LIST_ADD_ITEM_SUCCESS = "AUTHOR_LIST_ADD_ITEM_SUCCESS";
export const AUTHOR_LIST_ADD_ITEM_ERROR = "AUTHOR_LIST_ADD_ITEM_ERROR";
export const AUTHOR_LIST_EDIT_ITEM_SUCCESS = "AUTHOR_LIST_EDIT_ITEM_SUCCESS";
export const AUTHOR_LIST_EDIT_ITEM_ERROR = "AUTHOR_LIST_EDIT_ITEM_ERROR";
export const AUTHOR_LIST_DELETE_ITEM = "AUTHOR_LIST_DELETE_ITEM";
export const AUTHOR_LIST_DELETE_ITEM_SUCCESS =
  "AUTHOR_LIST_DELETE_ITEM_SUCCESS";
export const AUTHOR_LIST_DELETE_ITEM_ERROR = "AUTHOR_LIST_DELETE_ITEM_ERROR";

/* GUIDE LIST APP */
export const GUIDE_LIST_GET_LIST = "GUIDE_LIST_GET_LIST";
export const GUIDE_LIST_GET_LIST_SUCCESS = "GUIDE_LIST_GET_LIST_SUCCESS";
export const GUIDE_LIST_GET_LIST_ERROR = "GUIDE_LIST_GET_LIST_ERROR";
export const GUIDE_LIST_ADD_ITEM = "GUIDE_LIST_ADD_ITEM";
export const GUIDE_LIST_ADD_ITEM_SUCCESS = "GUIDE_LIST_ADD_ITEM_SUCCESS";
export const GUIDE_LIST_ADD_ITEM_ERROR = "GUIDE_LIST_ADD_ITEM_ERROR";
export const GUIDE_LIST_EDIT_ITEM = "GUIDE_LIST_EDIT_ITEM";
export const GUIDE_LIST_EDIT_ITEM_SUCCESS = "GUIDE_LIST_EDIT_ITEM_SUCCESS";
export const GUIDE_LIST_EDIT_ITEM_ERROR = "GUIDE_LIST_EDIT_ITEM_ERROR";
export const GUIDE_LIST_DELETE_ITEM = "GUIDE_LIST_DELETE_ITEM";
export const GUIDE_LIST_DELETE_ITEM_SUCCESS = "GUIDE_LIST_DELETE_ITEM_SUCCESS";
export const GUIDE_LIST_DELETE_ITEM_ERROR = "GUIDE_LIST_DELETE_ITEM_ERROR";

export const GUIDE_LIST_REARRANGE_ITEM = "GUIDE_LIST_REARRANGE_ITEM";
export const GUIDE_LIST_REARRANGE_ITEM_SUCCESS =
  "GUIDE_LIST_REARRANGE_ITEM_SUCCESS";
export const GUIDE_LIST_REARRANGE_ITEM_ERROR =
  "GUIDE_LIST_REARRANGE_ITEM_ERROR";

export const CATEGORY_LIST_GET_LIST_ACTIVE = "CATEGORY_LIST_GET_LIST_ACTIVE";
export const CATEGORY_LIST_GET_LIST_SUCCESS_ACTIVE =
  "CATEGORY_LIST_GET_LIST_SUCCESS_ACTIVE";
export const CATEGORY_LIST_GET_LIST_ERROR_ACTIVE =
  "CATEGORY_LIST_GET_LIST_ERROR_ACTIVE";

export const AUTHOR_LIST_GET_LIST_ACTIVE = "AUTHOR_LIST_GET_LIST_ACTIVE";
export const AUTHOR_LIST_GET_LIST_SUCCESS_ACTIVE =
  "AUTHOR_LIST_GET_LIST_SUCCESS_ACTIVE";
export const AUTHOR_LIST_GET_LIST_ERROR_ACTIVE =
  "AUTHOR_LIST_GET_LIST_ERROR_ACTIVE";

/* Stadium LIST APP */
export const STADIUM_LIST_GET_LIST = "STADIUM_LIST_GET_LIST";
export const STADIUM_LIST_GET_LIST_SUCCESS = "STADIUM_LIST_GET_LIST_SUCCESS";
export const STADIUM_LIST_GET_LIST_ERROR = "STADIUM_LIST_GET_LIST_ERROR";
export const STADIUM_LIST_ADD_ITEM = "STADIUM_LIST_ADD_ITEM";
export const STADIUM_LIST_ADD_ITEM_SUCCESS = "STADIUM_LIST_ADD_ITEM_SUCCESS";
export const STADIUM_LIST_ADD_ITEM_ERROR = "STADIUM_LIST_ADD_ITEM_ERROR";
export const STADIUM_LIST_DELETE_ITEM = "STADIUM_LIST_DELETE_ITEM";
export const STADIUM_LIST_DELETE_ITEM_SUCCESS =
  "STADIUM_LIST_DELETE_ITEM_SUCCESS";
export const STADIUM_LIST_DELETE_ITEM_ERROR = "STADIUM_LIST_DELETE_ITEM_ERROR";

/* Stadium LIST APP */
export const SPORT_LIST_GET_LIST = "SPORT_LIST_GET_LIST";
export const SPORT_LIST_GET_LIST_SUCCESS = "SPORT_LIST_GET_LIST_SUCCESS";
export const SPORT_LIST_GET_LIST_ERROR = "SPORT_LIST_GET_LIST_ERROR";
export const SPORT_LIST_ADD_ITEM = "SPORT_LIST_ADD_ITEM";
export const SPORT_LIST_ADD_ITEM_SUCCESS = "SPORT_LIST_ADD_ITEM_SUCCESS";
export const SPORT_LIST_ADD_ITEM_ERROR = "SPORT_LIST_ADD_ITEM_ERROR";
export const SPORT_LIST_DELETE_ITEM = "SPORT_LIST_DELETE_ITEM";
export const SPORT_LIST_DELETE_ITEM_SUCCESS ="SPORT_LIST_DELETE_ITEM_SUCCESS";
export const SPORT_LIST_DELETE_ITEM_ERROR = "SPORT_LIST_DELETE_ITEM_ERROR";

/* Court LIST APP */
export const COURT_LIST_GET_LIST = "COURT_LIST_GET_LIST";
export const COURT_LIST_GET_LIST_SUCCESS = "COURT_LIST_GET_LIST_SUCCESS";
export const COURT_LIST_GET_LIST_ERROR = "COURT_LIST_GET_LIST_ERROR";
export const COURT_LIST_ADD_ITEM = "COURT_LIST_ADD_ITEM";
export const COURT_LIST_ADD_ITEM_SUCCESS = "COURT_LIST_ADD_ITEM_SUCCESS";
export const COURT_LIST_ADD_ITEM_ERROR = "COURT_LIST_ADD_ITEM_ERROR";
export const COURT_LIST_EDIT_ITEM = "COURT_LIST_EDIT_ITEM";
export const COURT_LIST_EDIT_ITEM_SUCCESS = "COURT_LIST_EDIT_ITEM_SUCCESS";
export const COURT_LIST_EDIT_ITEM_ERROR = "COURT_LIST_EDIT_ITEM_ERROR";
export const COURT_LIST_DELETE_ITEM = "COURT_LIST_DELETE_ITEM";
export const COURT_LIST_DELETE_ITEM_SUCCESS =
  "COURT_LIST_DELETE_ITEM_SUCCESS";
export const COURT_LIST_DELETE_ITEM_ERROR = "COURT_LIST_DELETE_ITEM_ERROR";

/* Stadium admins LIST APP */
export const STADIUM_ADMIN_LIST_GET_LIST = "STADIUM_ADMIN_LIST_GET_LIST";
export const STADIUM_ADMIN_LIST_GET_LIST_SUCCESS =
  "STADIUM_ADMIN_LIST_GET_LIST_SUCCESS";
export const STADIUM_ADMIN_LIST_GET_LIST_ERROR =
  "STADIUM_ADMIN_LIST_GET_LIST_ERROR";
export const STADIUM_ADMIN_LIST_ADD_ITEM = "STADIUM_ADMIN_LIST_ADD_ITEM";
export const STADIUM_ADMIN_LIST_ADD_ITEM_SUCCESS =
  "STADIUM_ADMIN_LIST_ADD_ITEM_SUCCESS";
export const STADIUM_ADMIN_LIST_ADD_ITEM_ERROR =
  "STADIUM_ADMIN_LIST_ADD_ITEM_ERROR";
export const STADIUM_ADMIN_LIST_EDIT_ITEM = "STADIUM_ADMIN_LIST_EDIT_ITEM";
export const STADIUM_ADMIN_LIST_EDIT_ITEM_SUCCESS =
  "STADIUM_ADMIN_LIST_EDIT_ITEM_SUCCESS";
export const STADIUM_ADMIN_LIST_EDIT_ITEM_ERROR =
  "STADIUM_ADMIN_LIST_EDIT_ITEM_ERROR";
export const STADIUM_ADMIN_LIST_DELETE_ITEM = "STADIUM_ADMIN_LIST_DELETE_ITEM";
export const STADIUM_ADMIN_LIST_DELETE_ITEM_SUCCESS =
  "STADIUM_ADMIN_LIST_DELETE_ITEM_SUCCESS";
export const STADIUM_ADMIN_LIST_DELETE_ITEM_ERROR =
  "STADIUM_ADMIN_LIST_DELETE_ITEM_ERROR";

/* Stadium staff LIST APP */
export const STADIUM_STAFF_LIST_GET_LIST = "STADIUM_STAFF_LIST_GET_LIST";
export const STADIUM_STAFF_LIST_GET_LIST_SUCCESS =
  "STADIUM_STAFF_LIST_GET_LIST_SUCCESS";
export const STADIUM_STAFF_LIST_GET_LIST_ERROR =
  "STADIUM_STAFF_LIST_GET_LIST_ERROR";
export const STADIUM_STAFF_LIST_ADD_ITEM = "STADIUM_STAFF_LIST_ADD_ITEM";
export const STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS =
  "STADIUM_STAFF_LIST_ADD_ITEM_SUCCESS";
export const STADIUM_STAFF_LIST_ADD_ITEM_ERROR =
  "STADIUM_STAFF_LIST_ADD_ITEM_ERROR";
export const STADIUM_STAFF_LIST_EDIT_ITEM = "STADIUM_STAFF_LIST_EDIT_ITEM";
export const STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS =
  "STADIUM_STAFF_LIST_EDIT_ITEM_SUCCESS";
export const STADIUM_STAFF_LIST_EDIT_ITEM_ERROR =
  "STADIUM_STAFF_LIST_EDIT_ITEM_ERROR";
export const STADIUM_STAFF_LIST_DELETE_ITEM = "STADIUM_STAFF_LIST_DELETE_ITEM";
export const STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS =
  "STADIUM_STAFF_LIST_DELETE_ITEM_SUCCESS";
export const STADIUM_STAFF_LIST_DELETE_ITEM_ERROR =
  "STADIUM_STAFF_LIST_DELETE_ITEM_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP */
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* CHAT APP */
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

/* REVIEW APP */
export const GET_REVIEW_LIST = "GET_REVIEW_LIST";
export const GET_REVIEW_LIST_SUCCESS = "GET_REVIEW_LIST_SUCCESS";
export const GET_REVIEW_LIST_ERROR = "GET_REVIEW_LIST_ERROR";
export const REVIEW_HIDE_ITEM = "REVIEW_HIDE_ITEM";
export const REVIEW_HIDE_ITEM_SUCCESS = "REVIEW_HIDE_ITEM_SUCCESS";
export const REVIEW_HIDE_ITEM_ERROR = "REVIEW_HIDE_ITEM_ERROR";
export const REVIEW_REPORT_ITEM = "REVIEW_REPORT_ITEM";
export const REVIEW_REPORT_ITEM_SUCCESS = "REVIEW_REPORT_ITEM_SUCCESS";
export const REVIEW_REPORT_ITEM_ERROR = "REVIEW_REPORT_ITEM_ERROR";

export const BOOKING_LIST_GET_LIST = "BOOKING_LIST_GET_LIST";
export const BOOKING_LIST_GET_LIST_SUCCESS = "BOOKING_LIST_GET_LIST_SUCCESS";
export const BOOKING_LIST_GET_LIST_ERROR = "BOOKING_LIST_GET_LIST_ERROR";
export const BOOKING_COURT_LIST_GET_LIST = "BOOKING_COURT_LIST_GET_LIST";
export const BOOKING_COURT_LIST_GET_LIST_SUCCESS =
  "BOOKING_COURT_LIST_GET_LIST_SUCCESS";
export const BOOKING_COURT_LIST_GET_LIST_ERROR =
  "BOOKING_COURT_LIST_GET_LIST_ERROR";
export const BOOKING_STADIUM_LIST_GET_LIST = "BOOKING_STADIUM_LIST_GET_LIST";
export const BOOKING_STADIUM_LIST_GET_LIST_SUCCESS =
  "BOOKING_STADIUM_LIST_GET_LIST_SUCCESS";
export const BOOKING_STADIUM_LIST_GET_LIST_ERROR =
  "BOOKING_STADIUM_LIST_GET_LIST_ERROR";
export const BOOKING_LIST_ADD_ITEM = "BOOKING_LIST_ADD_ITEM";
export const BOOKING_LIST_ADD_ITEM_SUCCESS = "BOOKING_LIST_ADD_ITEM_SUCCESS";
export const BOOKING_LIST_ADD_ITEM_ERROR = "BOOKING_LIST_ADD_ITEM_ERROR";
export const BOOKING_LIST_EDIT_ITEM = "BOOKING_LIST_EDIT_ITEM";
export const BOOKING_LIST_EDIT_ITEM_SUCCESS = "BOOKING_LIST_EDIT_ITEM_SUCCESS";
export const BOOKING_LIST_EDIT_ITEM_ERROR = "BOOKING_LIST_EDIT_ITEM_ERROR";

export const BOOKING_LIST_EDIT_ITEM_STATUS = "BOOKING_LIST_EDIT_ITEM_STATUS";
export const BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS =
  "BOOKING_LIST_EDIT_ITEM_STATUS_SUCCESS";
export const BOOKING_LIST_EDIT_ITEM_STATUS_ERROR =
  "BOOKING_LIST_EDIT_ITEM_STATUS_ERROR";

export const BOOKING_LIST_DELETE_ITEM = "BOOKING_LIST_DELETE_ITEM";
export const BOOKING_LIST_DELETE_ITEM_SUCCESS =
  "BOOKING_LIST_DELETE_ITEM_SUCCESS";
export const BOOKING_LIST_DELETE_ITEM_ERROR = "BOOKING_LIST_DELETE_ITEM_ERROR";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./todo/actions";
export * from "./bookings/actions";
export * from "./stadium/actions";
export * from "./sport/actions";
export * from "./court/actions";
export * from "./stadium-detail/actions";
export * from "./stadiumAdmins/actions";
export * from "./stadium-staff/actions";
