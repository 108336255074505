import {
  SPORT_LIST_GET_LIST,
  SPORT_LIST_GET_LIST_SUCCESS,
  SPORT_LIST_GET_LIST_ERROR,
  SPORT_LIST_ADD_ITEM,
  SPORT_LIST_ADD_ITEM_SUCCESS,
  SPORT_LIST_ADD_ITEM_ERROR,
  SPORT_LIST_DELETE_ITEM,
  SPORT_LIST_DELETE_ITEM_ERROR,
  SPORT_LIST_DELETE_ITEM_SUCCESS,
} from "../actions";

const INIT_STATE = {
  sportItems: [],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPORT_LIST_GET_LIST:
      return { ...state, loading: false };
    case SPORT_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, sportItems: action.payload };
    case SPORT_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    case SPORT_LIST_ADD_ITEM:
      return { ...state, loading: false };
    case SPORT_LIST_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, sportItems: action.payload };
    case SPORT_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case SPORT_LIST_DELETE_ITEM:
      return { ...state, loading: false };
    case SPORT_LIST_DELETE_ITEM_SUCCESS: {
      const sportItems = state.sportItems.filter(
        (element) => element.id !== action.payload
      );
      return { ...state, loading: true, sportItems: [...sportItems] };
    }
    case SPORT_LIST_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
