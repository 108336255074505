import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "../../helpers/axios";
import { servicePath } from "constants/defaultValues";
import {
  SPORT_LIST_GET_LIST,
  SPORT_LIST_ADD_ITEM,
  SPORT_LIST_DELETE_ITEM,
} from "../actions";

import {
  getSportListSuccess,
  getSportListError,
  addSportItemSuccess,
  addSportItemError,
  deleteSportItemSuccess,
  deleteSportItemError,
} from "./actions";

const apiUrl = `${servicePath}/sports`;

//#region Author List

export function* watchGetSportList() {
  yield takeEvery(SPORT_LIST_GET_LIST, getSportListItems);
}

function* getSportListItems() {
  try {
    const response = yield call(getSportListRequest);
    yield put(getSportListSuccess(response));
  } catch (error) {
    yield put(getSportListError(error));
  }
}

const getSportListRequest = async () => {
  try {
    let user = JSON.parse(localStorage.getItem("app_current_user"));

    let response;
    if (user?.roles === "Sport-Admin" || user?.roles === "Sport-Staff") {
      response = await axios.get(`${apiUrl}?userId=${user?.id}`);
    } else {
      response = await axios.get(`${apiUrl}`);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add Guide

export function* watchAddItem() {
  yield takeEvery(SPORT_LIST_ADD_ITEM, addSportItem);
}

function* addSportItem({ payload }) {
  try {
    const response = yield call(addSportItemRequest, payload);
    yield put(addSportItemSuccess(response));
  } catch (error) {
    yield put(addSportItemError(error));
  }
}

const addSportItemRequest = async (item) => {
  try {
    console.log("herere");
    if (item.edit) {
      await axios.patch(apiUrl + "/" + item.id, item);
    } else {
      console.log("in the post call of add sport", item);
      await axios.post(apiUrl, item);
    }

    let response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#region Delete Sport

export function* wathcDeleteItem() {
  yield takeEvery(SPORT_LIST_DELETE_ITEM, deleteSportItem);
}

function* deleteSportItem({ payload }) {
  try {
    yield call(deleteSportItemRequest, payload);
    yield put(deleteSportItemSuccess(payload));
  } catch (error) {
    yield put(deleteSportItemError(error));
  }
}

const deleteSportItemRequest = async (item) => {
  try {
    let res = await axios.delete(apiUrl + "/" + item);
    //let response = await axios.get(apiUrl);

    console.log("del in res", res.data);
    return response.data;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};

//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetSportList),
    fork(watchAddItem),
    fork(wathcDeleteItem),
  ]);
}
