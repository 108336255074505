export const UserRole = {
  Admin: 0,
};

export const MALAYSIA_PHONE_REGEX =
  /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/;
export const MALAYSIA_POSTCODE_REGEX = /^\\d{5}$/;

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const currentUser = {};

export const adminRoot = "/app";
export const loginPage = "/user/login";
export const stadiumDetail = "/app/venue-management/venue-detail";
export const schedule = "/app/venue-management/schedule";
// export const servicePath = "http://localhost:8800/api";
export const servicePath = "https://hala-api-service.herokuapp.com/api";
// export const servicePath = "https://hala-api-service-prod-550b28eb9365.herokuapp.com/api"; 
export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = true;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];
