import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import axios from 'axios';
import { servicePath } from 'constants/defaultValues';
import {
  STADIUM_ADMIN_LIST_GET_LIST,
  STADIUM_ADMIN_LIST_ADD_ITEM,
  STADIUM_ADMIN_LIST_EDIT_ITEM,
  STADIUM_ADMIN_LIST_DELETE_ITEM,
} from '../actions';

import {
  getStadiumAdminListSuccess,
  getStadiumAdminListError,
  addStadiumAdminItemSuccess,
  addStadiumAdminItemError,
  editStadiumAdminItemSuccess,
  editStadiumAdminItemError,
  deleteStadiumAdminItemSuccess,
  deleteStadiumAdminItemError,
} from './actions';

const apiUrl = `${servicePath}/stadium-admins`;
let user = JSON.parse(localStorage.getItem("app_current_user"));

//#region StadiumAdmin List

export function* watchGetList() {
  yield takeEvery(STADIUM_ADMIN_LIST_GET_LIST, getStadiumAdminListItems);
}

function* getStadiumAdminListItems({ payload }) {
  try {
    const response = yield call(getStadiumAdminListRequest, payload );
    yield put(getStadiumAdminListSuccess(response));
  } catch (error) {
    yield put(getStadiumAdminListError(error));
  }
}

const getStadiumAdminListRequest = async (item) => {
  try {
    let user = JSON.parse(localStorage.getItem("app_current_user"));
    let response;
    if ((user?.roles === "Stadium-Admin" || user?.roles === "Admin") && item) {
      response = await axios.get(`${apiUrl}?stadiumId=${item}`);
    } else {
      response = await axios.get(apiUrl);
    }

    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Add StadiumAdmin

export function* wathcAddItem() {
  yield takeEvery(STADIUM_ADMIN_LIST_ADD_ITEM, addStadiumAdminItem);
}

function* addStadiumAdminItem({ payload }) {
  try {
    const response = yield call(addStadiumAdminItemRequest, payload);
    yield put(addStadiumAdminItemSuccess(response));
  } catch (error) {
    yield put(addStadiumAdminItemError(error));
  }
}

const addStadiumAdminItemRequest = async (item) => {
  try {
    await axios.post(apiUrl + '/create', item);
    let response;
    if ((user?.roles === "Stadium-Admin" || user?.roles === "Admin") && item?.stadiumIds[0] && item?.isStadiumStaff) {
      response = await axios.get(`${apiUrl}?stadiumId=${item?.stadiumIds[0]}`);
    } else {
      response = await axios.get(apiUrl);
    }
    return response.data;
  } catch (error) {
    NotificationManager.error("Failed! Email or Phone is already in use!", 'Register Error');
    return error;
  }
};

//#endregion

//#region Edit StadiumAdmin

export function* wathcEditItem() {
  yield takeEvery(STADIUM_ADMIN_LIST_EDIT_ITEM, editStadiumAdminItem);
}

function* editStadiumAdminItem({ payload }) {
  try {
    const response = yield call(editStadiumAdminItemRequest, payload);
    // if(!response.message) {
      yield put(editStadiumAdminItemSuccess(response));
    // } else {
    //   yield put(editStadiumAdminItemError(response.message));
    // }
  } catch (error) {
    yield put(editStadiumAdminItemError(error));
  }
}

const editStadiumAdminItemRequest = async (item) => {
  try {
    await axios.put(apiUrl + '/' + item.id, item);
    let response;
    if ((user?.roles === "Stadium-Admin" || user?.roles === "Admin") && item?.stadiumIds?.[0] && item?.isStadiumStaff) {
      response = await axios.get(`${apiUrl}?stadiumId=${item?.stadiumIds[0]}`);
    } else {
      response = await axios.get(apiUrl);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Delete StadiumAdmin

export function* wathcDeleteItem() {
  yield takeEvery(STADIUM_ADMIN_LIST_DELETE_ITEM, deleteStadiumAdminItem);
}

function* deleteStadiumAdminItem({ payload }) {
  try {
    yield call(deleteStadiumAdminItemRequest, payload);
    yield put(deleteStadiumAdminItemSuccess(payload));
  } catch (error) {
    yield put(deleteStadiumAdminItemError(error));
  }
}

const deleteStadiumAdminItemRequest = async (item) => {
  try {
    let res = await axios.delete(apiUrl + '/' + item);
    console.log('del in res', res.data);
    return res.data;
  } catch (error) {
    console.log('err', error);
    return error;
  }
};

//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcDeleteItem),
  ]);
}
