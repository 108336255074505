import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import axios from 'axios';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// Add a request interceptor
axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('accessToken');
	console.log('token', token)
	if (token) {
		config.headers.common['x-access-token'] = token;
	} else {
		config.headers.common['x-access-token'] = null;
	}
	//config.headers.Authorization =  token;
    return config;
});

// export function configureStore(initialState) {
// 	const store = createStore(
// 		reducers,
// 		initialState,
// 		compose(applyMiddleware(...middlewares))
// 	);

// 	sagaMiddleware.run(sagas);

// 	if (module.hot) {
// 		module.hot.accept('./reducers', () => {
// 			const nextRootReducer = require('./reducers');
// 			store.replaceReducer(nextRootReducer);
// 		});
// 	}

// 	return store;
// }


const configureStore = () => {
	const store = createStore(
		reducers,
		compose(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(sagas);
	return store;
};
 
const store = configureStore() // exported this instead;

export default store;
