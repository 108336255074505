import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import todoApp from "./todo/reducer";
import stadiumListApp from "./stadium/reducer";
import sportListApp from "./sport/reducer";
import courtListApp from "./court/reducer";
import stadiumDetailApp from "./stadium-detail/reducer";
import bookingsApp from "./bookings/reducer";
import stadiumAdminListApp from "./stadiumAdmins/reducer";
import stadiumStaffListApp from "./stadium-staff/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  stadiumAdminListApp,
  stadiumStaffListApp,
  stadiumListApp,
  sportListApp,
  courtListApp,
  stadiumDetailApp,
  bookingsApp,
});

export default reducers;
