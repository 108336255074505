import axios from "axios";
import { loginPage } from "constants/defaultValues";

axios.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = loginPage;
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
