import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "./../../helpers/axios";
import { servicePath } from "constants/defaultValues";
import {
  BOOKING_LIST_GET_LIST,
  BOOKING_COURT_LIST_GET_LIST,
  BOOKING_STADIUM_LIST_GET_LIST,
  BOOKING_LIST_ADD_ITEM,
  BOOKING_LIST_EDIT_ITEM,
  BOOKING_LIST_EDIT_ITEM_STATUS,
  BOOKING_LIST_DELETE_ITEM,
} from "../actions";

import {
  getBookingListSuccess,
  getBookingListError,
  getCourtBookingListSuccess,
  getCourtBookingListError,
  getStadiumBookingListSuccess,
  getStadiumBookingListError,
  addBookingItemSuccess,
  addBookingItemError,
  editBookingItemSuccess,
  editBookingItemError,
  editBookingItemStatusSuccess,
  editBookingItemStatusError,
  deleteBookingItemSuccess,
  deleteBookingItemError,
} from "./actions";
import { NotificationManager } from 'components/common/react-notifications';

const apiUrl = `${servicePath}/bookings`;

//#region Booking List
export function* watchGetList() {
  yield takeEvery(BOOKING_LIST_GET_LIST, getBookingListItems);
}

function* getBookingListItems() {
  try {
    const response = yield call(getBookingListRequest);
    yield put(getBookingListSuccess(response));
  } catch (error) {
    yield put(getBookingListError(error));
  }
}

const getBookingListRequest = async () => {
  try {
    let response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Court Booking List
export function* watchCourtBookingGetList() {
  yield takeEvery(BOOKING_COURT_LIST_GET_LIST, getCourtBookingListItems);
}

function* getCourtBookingListItems({ payload }) {
  try {
    const response = yield call(getCourtBookingListRequest, payload);
    yield put(getCourtBookingListSuccess(response));
  } catch (error) {
    yield put(getCourtBookingListError(error));
  }
}

const getCourtBookingListRequest = async (id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };
    let response = await axios.get(`${apiUrl}/${id}/court`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Stadium Booking List
export function* watchStadiumBookingGetList() {
  yield takeEvery(BOOKING_STADIUM_LIST_GET_LIST, getStadiumBookingListItems);
}

function* getStadiumBookingListItems({ payload }) {
  try {
    const response = yield call(getStadiumBookingListRequest, payload);
    yield put(getStadiumBookingListSuccess(response));
  } catch (error) {
    yield put(getStadiumBookingListError(error));
  }
}

const getStadiumBookingListRequest = async (id) => {
  //console.log("getStadiumBookingListRequest", id)
  try {
    let response = await axios.get(`${apiUrl}/${id}/stadium`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Add Booking
export function* wathcAddItem() {
  yield takeEvery(BOOKING_LIST_ADD_ITEM, addBookingItem);
}

function* addBookingItem({ payload }) {
  try {
    const response = yield call(addBookingItemRequest, payload);
    yield put(addBookingItemSuccess(response));
  } catch (error) {
    yield put(addBookingItemError(error));
  }
}

const addBookingItemRequest = async (item) => {
  try {
    const token = localStorage.getItem("accessToken");
    const headers = {
      "x-access-token": token,
    };
    const response = await axios.post(apiUrl, item);
    if (response?.data?.type === "Error") {
      NotificationManager.error(response?.data?.message, 'Booking failed', 3000, null, null, '');
    }
    return response.data;
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Edit Booking
export function* wathcEditItem() {
  yield takeEvery(BOOKING_LIST_EDIT_ITEM, editBookingItem);
}

function* editBookingItem({ payload }) {
  try {
    const response = yield call(editBookingItemRequest, payload);
    yield put(editBookingItemSuccess(response));
  } catch (error) {
    yield put(editBookingItemError(error));
  }
}

const editBookingItemRequest = async (item) => {
  try {
    await axios.patch(apiUrl + "/" + item.id, item);
    let response = await axios.get(`${apiUrl}/${item.stadiumId}/stadium`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Edit Booking Status
export function* wathcEditItemStatus() {
  yield takeEvery(BOOKING_LIST_EDIT_ITEM_STATUS, editBookingItemStatus);
}

function* editBookingItemStatus({ payload }) {
  try {
    const response = yield call(editBookingItemrStatusRequest, payload);
    yield put(editBookingItemStatusSuccess(response));
  } catch (error) {
    yield put(editBookingItemStatusError(error));
  }
}

const editBookingItemrStatusRequest = async (item) => {
  try {
    await axios.patch(apiUrl + "/" + item.id + "/status", item);
    let response = await axios.get(`${apiUrl}/${item.stadiumId}/stadium`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//#endregion

//#region Delete Booking

export function* wathcDeleteItem() {
  yield takeEvery(BOOKING_LIST_DELETE_ITEM, deleteBookingItem);
}

function* deleteBookingItem({ payload }) {
  try {
    yield call(deleteBookingItemRequest, payload);
    yield put(deleteBookingItemSuccess(payload));
  } catch (error) {
    yield put(deleteBookingItemError(error));
  }
}

const deleteBookingItemRequest = async (item) => {
  try {
    console.log("deleteBookingItemRequest", item);
    let res = await axios.delete(
      `${apiUrl}/${item.bookingId}?slotId=${item.slotId}`
    );
    return res.data;
  } catch (error) {
    console.log("err", error);
    return error;
  }
};
//#endregion

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchStadiumBookingGetList),
    fork(watchCourtBookingGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcEditItemStatus),
    fork(wathcDeleteItem),
  ]);
}
