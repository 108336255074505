import {
  STADIUM_LIST_GET_LIST,
  STADIUM_LIST_GET_LIST_SUCCESS,
  STADIUM_LIST_GET_LIST_ERROR,
  STADIUM_LIST_ADD_ITEM,
  STADIUM_LIST_ADD_ITEM_SUCCESS,
  STADIUM_LIST_ADD_ITEM_ERROR,
  STADIUM_LIST_DELETE_ITEM,
  STADIUM_LIST_DELETE_ITEM_ERROR,
  STADIUM_LIST_DELETE_ITEM_SUCCESS,
} from "../actions";

const INIT_STATE = {
  stadiumItems: [],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STADIUM_LIST_GET_LIST:
      return { ...state, loading: false };
    case STADIUM_LIST_GET_LIST_SUCCESS:
      return { ...state, loading: true, stadiumItems: action.payload };
    case STADIUM_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_LIST_ADD_ITEM:
      return { ...state, loading: false };
    case STADIUM_LIST_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, stadiumItems: action.payload };
    case STADIUM_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    case STADIUM_LIST_DELETE_ITEM:
      return { ...state, loading: false };
    case STADIUM_LIST_DELETE_ITEM_SUCCESS: {
      const stadiumItems = state.stadiumItems.filter(
        (element) => element.id !== action.payload
      );
      return { ...state, loading: true, stadiumItems: [...stadiumItems] };
    }
    case STADIUM_LIST_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
